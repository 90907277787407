<template>
  <div class="base-bread-item">
    <font-awesome-icon v-if="to==='/'" icon="fa-solid fa-house-chimney"/>
    <RouterLink v-if="to" :to="to">
      <slot/>
    </RouterLink>
    <span v-else><slot/></span>
  </div>
</template>
<script>
export default {
  name: 'BaseBreadItem',
  props: {
    to: {
      type: [String, Object],
      default: ''
    }
  }
}
</script>
