<template>
  <div id="solve-info-css">
    <BaseBread style="margin-left: 5vh;">
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem to="/productadv">解决方案</BaseBreadItem>
      <BaseBreadItem>{{ dataSource.articleTitle }}</BaseBreadItem>
    </BaseBread>
    <div>
      <div style="width: 100%;padding:0 30px 50px 30px;">
        <div style="width:80%;padding-left: 32vh">
          <h1 style="text-align: center;font-size: 30px">{{ dataSource.articleTitle }}</h1>
          <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
          </div>
          <div class="edit_container" v-html="dataSource.articleContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {ref} from "vue";
import {findArticleDetailById} from "@/api/home";
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";

export default {
  name: 'SolveInfo',
  components: {BaseBreadItem, BaseBread},
  setup() {
    const route = useRoute()
    const dataSource = ref([])
    let link = route.params.link
    const time = ref()
    // findArticleDetailByFlag(link).then(data => {
    //   dataSource.value = data.data
    //   //获得当前时间
    //   time.value = data.time
    // })
    findArticleDetailById(link).then(data => {
      dataSource.value = data.data
      //获得当前时间
      time.value = data.time
    })
    return {dataSource}
  }
}
</script>

<style scoped lang="less">
.edit_container {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#solve-info-css .article-detail {
  width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
}

#solve-info-css {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

#solve-info-css {


  .article-detail {
    width: 1200px;
    background: #fff;
    padding-left: 30px;
    padding-right: 30px;
  }

  .article-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .article-description {
    margin-bottom: 20px;
    background-color: #f2f2f2;
    padding: 10px;
  }

  .article-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .article-category {
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .article-date {
    color: #888;
  }

  .article-content {
    margin-top: 20px;
    line-height: 1.5;
  }
}
</style>
